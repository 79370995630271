@tailwind base;
@tailwind components;
@tailwind utilities;

/*
 * The Typekit service used to deliver this font or fonts for use on websites
 * is provided by Adobe and is subject to these Terms of Use
 * http://www.adobe.com/products/eulas/tou_typekit. For font license
 * information, see the list below.
 *
 * proxima-nova:
 *   - http://typekit.com/eulas/00000000000000007735e602
 *   - http://typekit.com/eulas/00000000000000007735e60a
 *   - http://typekit.com/eulas/00000000000000007735e603
 *   - http://typekit.com/eulas/00000000000000007735e604
 *   - http://typekit.com/eulas/00000000000000007735e611
 *   - http://typekit.com/eulas/00000000000000007735e622
 *   - http://typekit.com/eulas/00000000000000007735e601
 *   - http://typekit.com/eulas/00000000000000007735e608
 *   - http://typekit.com/eulas/00000000000000007735e60d
 *   - http://typekit.com/eulas/00000000000000007735e619
 *   - http://typekit.com/eulas/00000000000000007735e609
 *   - http://typekit.com/eulas/00000000000000007735e616
 *   - http://typekit.com/eulas/00000000000000007735e606
 *   - http://typekit.com/eulas/00000000000000007735e607
 *   - http://typekit.com/eulas/00000000000000007735e605
 *   - http://typekit.com/eulas/00000000000000007735e60e
 *
 * © 2009-2024 Adobe Systems Incorporated. All Rights Reserved.
 */
/*{"last_published":"2024-10-24 16:02:44 UTC"}*/

@import url('https://p.typekit.net/p.css?s=1&k=xkm7ajp&ht=tk&f=137.138.139.140.169.170.171.172.173.174.175.176.5474.5475.25136.25137&a=13827064&app=typekit&e=css');

@font-face {
  font-family: 'proxima-nova';
  src:
    url('https://use.typekit.net/af/e3ed45/00000000000000007735e602/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n9&v=3')
      format('woff2'),
    url('https://use.typekit.net/af/e3ed45/00000000000000007735e602/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n9&v=3')
      format('woff'),
    url('https://use.typekit.net/af/e3ed45/00000000000000007735e602/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n9&v=3')
      format('opentype');
  font-display: auto;
  font-style: normal;
  font-weight: 900;
  font-stretch: normal;
}

@font-face {
  font-family: 'proxima-nova';
  src:
    url('https://use.typekit.net/af/d83550/00000000000000007735e60a/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i9&v=3')
      format('woff2'),
    url('https://use.typekit.net/af/d83550/00000000000000007735e60a/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i9&v=3')
      format('woff'),
    url('https://use.typekit.net/af/d83550/00000000000000007735e60a/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i9&v=3')
      format('opentype');
  font-display: auto;
  font-style: italic;
  font-weight: 900;
  font-stretch: normal;
}

@font-face {
  font-family: 'proxima-nova';
  src:
    url('https://use.typekit.net/af/2555e1/00000000000000007735e603/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3')
      format('woff2'),
    url('https://use.typekit.net/af/2555e1/00000000000000007735e603/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3')
      format('woff'),
    url('https://use.typekit.net/af/2555e1/00000000000000007735e603/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3')
      format('opentype');
  font-display: auto;
  font-style: normal;
  font-weight: 700;
  font-stretch: normal;
}

@font-face {
  font-family: 'proxima-nova';
  src:
    url('https://use.typekit.net/af/4de20a/00000000000000007735e604/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3')
      format('woff2'),
    url('https://use.typekit.net/af/4de20a/00000000000000007735e604/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3')
      format('woff'),
    url('https://use.typekit.net/af/4de20a/00000000000000007735e604/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3')
      format('opentype');
  font-display: auto;
  font-style: italic;
  font-weight: 700;
  font-stretch: normal;
}

@font-face {
  font-family: 'proxima-nova';
  src:
    url('https://use.typekit.net/af/8738d8/00000000000000007735e611/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n8&v=3')
      format('woff2'),
    url('https://use.typekit.net/af/8738d8/00000000000000007735e611/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n8&v=3')
      format('woff'),
    url('https://use.typekit.net/af/8738d8/00000000000000007735e611/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n8&v=3')
      format('opentype');
  font-display: auto;
  font-style: normal;
  font-weight: 800;
  font-stretch: normal;
}

@font-face {
  font-family: 'proxima-nova';
  src:
    url('https://use.typekit.net/af/58acf5/00000000000000007735e622/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i8&v=3')
      format('woff2'),
    url('https://use.typekit.net/af/58acf5/00000000000000007735e622/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i8&v=3')
      format('woff'),
    url('https://use.typekit.net/af/58acf5/00000000000000007735e622/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i8&v=3')
      format('opentype');
  font-display: auto;
  font-style: italic;
  font-weight: 800;
  font-stretch: normal;
}

@font-face {
  font-family: 'proxima-nova';
  src:
    url('https://use.typekit.net/af/154cda/00000000000000007735e601/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n1&v=3')
      format('woff2'),
    url('https://use.typekit.net/af/154cda/00000000000000007735e601/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n1&v=3')
      format('woff'),
    url('https://use.typekit.net/af/154cda/00000000000000007735e601/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n1&v=3')
      format('opentype');
  font-display: auto;
  font-style: normal;
  font-weight: 100;
  font-stretch: normal;
}

@font-face {
  font-family: 'proxima-nova';
  src:
    url('https://use.typekit.net/af/7283cd/00000000000000007735e608/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i1&v=3')
      format('woff2'),
    url('https://use.typekit.net/af/7283cd/00000000000000007735e608/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i1&v=3')
      format('woff'),
    url('https://use.typekit.net/af/7283cd/00000000000000007735e608/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i1&v=3')
      format('opentype');
  font-display: auto;
  font-style: italic;
  font-weight: 100;
  font-stretch: normal;
}

@font-face {
  font-family: 'proxima-nova';
  src:
    url('https://use.typekit.net/af/78aca8/00000000000000007735e60d/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3')
      format('woff2'),
    url('https://use.typekit.net/af/78aca8/00000000000000007735e60d/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3')
      format('woff'),
    url('https://use.typekit.net/af/78aca8/00000000000000007735e60d/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3')
      format('opentype');
  font-display: auto;
  font-style: normal;
  font-weight: 600;
  font-stretch: normal;
}

@font-face {
  font-family: 'proxima-nova';
  src:
    url('https://use.typekit.net/af/144da4/00000000000000007735e619/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i6&v=3')
      format('woff2'),
    url('https://use.typekit.net/af/144da4/00000000000000007735e619/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i6&v=3')
      format('woff'),
    url('https://use.typekit.net/af/144da4/00000000000000007735e619/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i6&v=3')
      format('opentype');
  font-display: auto;
  font-style: italic;
  font-weight: 600;
  font-stretch: normal;
}

@font-face {
  font-family: 'proxima-nova';
  src:
    url('https://use.typekit.net/af/efe4a5/00000000000000007735e609/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3')
      format('woff2'),
    url('https://use.typekit.net/af/efe4a5/00000000000000007735e609/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3')
      format('woff'),
    url('https://use.typekit.net/af/efe4a5/00000000000000007735e609/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3')
      format('opentype');
  font-display: auto;
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
}

@font-face {
  font-family: 'proxima-nova';
  src:
    url('https://use.typekit.net/af/3322cc/00000000000000007735e616/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3')
      format('woff2'),
    url('https://use.typekit.net/af/3322cc/00000000000000007735e616/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3')
      format('woff'),
    url('https://use.typekit.net/af/3322cc/00000000000000007735e616/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3')
      format('opentype');
  font-display: auto;
  font-style: italic;
  font-weight: 400;
  font-stretch: normal;
}

@font-face {
  font-family: 'proxima-nova';
  src:
    url('https://use.typekit.net/af/1be3c2/00000000000000007735e606/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3')
      format('woff2'),
    url('https://use.typekit.net/af/1be3c2/00000000000000007735e606/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3')
      format('woff'),
    url('https://use.typekit.net/af/1be3c2/00000000000000007735e606/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3')
      format('opentype');
  font-display: auto;
  font-style: normal;
  font-weight: 300;
  font-stretch: normal;
}

@font-face {
  font-family: 'proxima-nova';
  src:
    url('https://use.typekit.net/af/40d372/00000000000000007735e607/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i3&v=3')
      format('woff2'),
    url('https://use.typekit.net/af/40d372/00000000000000007735e607/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i3&v=3')
      format('woff'),
    url('https://use.typekit.net/af/40d372/00000000000000007735e607/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i3&v=3')
      format('opentype');
  font-display: auto;
  font-style: italic;
  font-weight: 300;
  font-stretch: normal;
}

@font-face {
  font-family: 'proxima-nova';
  src:
    url('https://use.typekit.net/af/23e139/00000000000000007735e605/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3')
      format('woff2'),
    url('https://use.typekit.net/af/23e139/00000000000000007735e605/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3')
      format('woff'),
    url('https://use.typekit.net/af/23e139/00000000000000007735e605/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3')
      format('opentype');
  font-display: auto;
  font-style: normal;
  font-weight: 500;
  font-stretch: normal;
}

@font-face {
  font-family: 'proxima-nova';
  src:
    url('https://use.typekit.net/af/79862c/00000000000000007735e60e/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i5&v=3')
      format('woff2'),
    url('https://use.typekit.net/af/79862c/00000000000000007735e60e/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i5&v=3')
      format('woff'),
    url('https://use.typekit.net/af/79862c/00000000000000007735e60e/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i5&v=3')
      format('opentype');
  font-display: auto;
  font-style: italic;
  font-weight: 500;
  font-stretch: normal;
}

.tk-proxima-nova {
  font-family: 'proxima-nova', sans-serif;
}

@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap');

/* Font Family */
@font-face {
  font-family: 'Montserrat';
  src: url('/public/fonts/Montserrat/Montserrat-VariableFont_wght.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat Italic';
  src: url('/public/fonts/Montserrat/Montserrat-Italic-VariableFont_wght.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

:root {
  --primary-blue: #499af4;
  --secondary-blue: #005ea4;
  --light-blue: #75b4ed;
  --background-cream: #dfd3c9;
  --accent-orange: #d56a4c;
  --light-black: #323335;
  --chizl-black: #3f4347;
}

.dark {
  color: white;
  background-color: #222222;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-size: 15px;

  font-optical-sizing: auto;
  font-style: normal;
  font-family: 'proxima-nova';
  /* font-family: 'Outfit', sans-serif; */
  /* font-family: 'Montserrat', sans-serif; */
  /* color: var(--light-black); */
}

body {
  background-color: #101011;
}

@layer components {
  .section-container {
    @apply max-w-section mx-auto w-full;
  }
  .flex-center {
    @apply flex items-center justify-center;
  }
  .text-truncate {
    @apply overflow-hidden whitespace-nowrap text-ellipsis;
  }
  .bg-gray-gradient {
    @apply bg-gradient-to-t from-[#363639] to-[#A1A1A1];
  }
  .text-gray-gradient {
    @apply bg-gradient-to-t from-[#363639] to-[#A1A1A1] bg-clip-text text-transparent;
  }
}

.react_markdown * {
  font-size: 13px !important;
}

/* Resizable Styling */
.react-resizable {
  position: relative;
}
.react-resizable-handle {
  position: absolute;
  background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBzdGFuZGFsb25lPSJubyI/Pg08IS0tIEdlbmVyYXRvcjogQWRvYmUgRmlyZXdvcmtzIENTNiwgRXhwb3J0IFNWRyBFeHRlbnNpb24gYnkgQWFyb24gQmVhbGwgKGh0dHA6Ly9maXJld29ya3MuYWJlYWxsLmNvbSkgLiBWZXJzaW9uOiAwLjYuMSAgLS0+DTwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DTxzdmcgaWQ9IlVudGl0bGVkLVBhZ2UlMjAxIiB2aWV3Qm94PSIwIDAgNiA2IiBzdHlsZT0iYmFja2dyb3VuZC1jb2xvcjojZmZmZmZmMDAiIHZlcnNpb249IjEuMSINCXhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHhtbDpzcGFjZT0icHJlc2VydmUiDQl4PSIwcHgiIHk9IjBweCIgd2lkdGg9IjZweCIgaGVpZ2h0PSI2cHgiDT4NCTxnIG9wYWNpdHk9IjAuMzAyIj4NCQk8cGF0aCBkPSJNIDYgNiBMIDAgNiBMIDAgNC4yIEwgNCA0LjIgTCA0LjIgNC4yIEwgNC4yIDAgTCA2IDAgTCA2IDYgTCA2IDYgWiIgZmlsbD0iIzAwMDAwMCIvPg0JPC9nPg08L3N2Zz4=');
  background-position: bottom right;
  padding: 0 3px 3px 0;
  background-repeat: no-repeat;
  background-origin: content-box;
  box-sizing: border-box;
  cursor: e-resize;
}
.react-resizable-handle-w {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  width: 4.5px;
  height: 65px;
  background: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNjRweCIgaGVpZ2h0PSI2NHB4IiB2aWV3Qm94PSItNi40OCAtNi40OCAzNi45NiAzNi45NiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiBzdHJva2U9IiMwMDAwMDAiIHN0cm9rZS13aWR0aD0iMC4wMDAyNDAwMDAwMDAwMDAwMDAwMyI+PGcgaWQ9IlNWR1JlcG9fYmdDYXJyaWVyIiBzdHJva2Utd2lkdGg9IjAiPjwvZz48ZyBpZD0iU1ZHUmVwb190cmFjZXJDYXJyaWVyIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiPjwvZz48ZyBpZD0iU1ZHUmVwb19pY29uQ2FycmllciI+IDxwYXRoIGQ9Ik0xNC41IDRDMTQuNSA1LjM4MDcxIDEzLjM4MDcgNi41IDEyIDYuNUMxMC42MTkzIDYuNSA5LjUgNS4zODA3MSA5LjUgNEM5LjUgMi42MTkyOSAxMC42MTkzIDEuNSAxMiAxLjVDMTMuMzgwNyAxLjUgMTQuNSAyLjYxOTI5IDE0LjUgNFoiIGZpbGw9IiMxNDE0MTQiPjwvcGF0aD4gPHBhdGggZD0iTTE0LjUgMTJDMTQuNSAxMy4zODA3IDEzLjM4MDcgMTQuNSAxMiAxNC41QzEwLjYxOTMgMTQuNSA5LjUgMTMuMzgwNyA5LjUgMTJDOS41IDEwLjYxOTMgMTAuNjE5MyA5LjUgMTIgOS41QzEzLjM4MDcgOS41IDE0LjUgMTAuNjE5MyAxNC41IDEyWiIgZmlsbD0iIzE0MTQxNCI+PC9wYXRoPiA8cGF0aCBkPSJNMTIgMjIuNUMxMy4zODA3IDIyLjUgMTQuNSAyMS4zODA3IDE0LjUgMjBDMTQuNSAxOC42MTkzIDEzLjM4MDcgMTcuNSAxMiAxNy41QzEwLjYxOTMgMTcuNSA5LjUgMTguNjE5MyA5LjUgMjBDOS41IDIxLjM4MDcgMTAuNjE5MyAyMi41IDEyIDIyLjVaIiBmaWxsPSIjMTQxNDE0Ij48L3BhdGg+IDwvZz48L3N2Zz4=');
  background-position: center;
  background-size: 20px;
  padding: 0 0 0 0;
  background-repeat: no-repeat;
  background-origin: content-box;
  background-color: var(--background-cream);
  opacity: 0.45;
  border-radius: 0 20px 20px 0;
}
.react-resizable-handle-w:hover {
  opacity: 0.25;
}

.no-select {
  user-select: none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px rgba(255, 0, 0, 0) inset !important;
  border-radius: 5px;
  padding: 0 5px;
  -webkit-text-fill-color: var(--light-black) !important;
  /* caret-color: #00191E !important; */
}
::-webkit-scrollbar {
  width: 10px; /* Width of the scrollbar */
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent; /* Transparent track */
}
body::-webkit-scrollbar-track {
  background: var(--chizl-black);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background-color: #d9d9d9; /* Button color */
  border-radius: 0px; /* Rounded corners */
  border: 2px solid transparent; /* Border color and width */
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background-color: #a6a6a6; /* Change color on hover if desired */
}
